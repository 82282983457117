<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        รับสินค้า - เข้าคลังแล้ว : PU2-4
      </h3>
    </div>
    <div class="card-title row ms-6 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <input
          v-model="searchInput"
          placeholder="ค้นหา"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-sm-1 text-end">
        <button
          @click="search"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          ค้นหา
        </button>
      </div>
    </div>
    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th>เลขที่เอกสาร</th>
              <th>รหัสผู้ขาย</th>
              <th>ชื่อผู้ขาย</th>
              <th>วันที่อนุมัติสั่งซื้อ</th>
              <th>มูลค่าสั่งซื้อ</th>
              <th>วันที่รับสินค้า</th>
              <th>มูลค่าสินค้ารับเข้า</th>
              <th>Detail</th>
              <th>ยกเลิก</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="9">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>{{ item.purchase_no ? item.purchase_no : "" }}</td>
              <td>{{ item.vendor_code ? item.vendor_code : "" }}</td>
              <td>{{ item.vendor_name ? item.vendor_name : "" }}</td>
              <td>
                {{
                  item.approved_date
                    ? formatDate(item.approved_date.substring(0, 10))
                    : ""
                }}
              </td>
              <td>{{ item.final_price }}</td>
              <td>
                {{
                  item.receive_date
                    ? formatDate(item.receive_date.substring(0, 10))
                    : ""
                }}
              </td>
              <td>{{ item.final_price }}</td>

              <td>
                <div class="row">
                  <div class="col-6">
                    <div
                      v-for="(item2, index) in item.purchaseOrderItems"
                      :key="index"
                    >
                      <!-- {{ item2.item_amt }}/ {{ item2.item_amt_recieve }} -->
                      <!-- {{ item2.item_amt !== item2.item_amt_recieve ? "แก้ไข" : "" }} -->
                      <button
                        v-if="item2.item_amt !== item2.item_amt_recieve"
                        type="button"
                        class="btn btn-link btn-color-muted btn-active-color-success p-0"
                        @click="goToEdit(item.id)"
                      >
                        <i class="bi bi-pencil-square"></i>
                      </button>
                    </div>
                  </div>

                  <div @mousedown="hoverExportBtn()" class="col-6">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success m-0 p-0"
                      @click="goToExport(item, index)"
                    >
                      <div
                        :class="
                          !loadingExport
                            ? ''
                            : index == indexExport
                            ? 'spinner-grow spinner-grow-sm text-light'
                            : ''
                        "
                        role="status"
                      >
                        <i class="bi bi-printer-fill"> </i>
                      </div>
                    </button>
                  </div>
                </div>
              </td>

              <!-- <td>
                <div
                  v-for="(item2, index) in item.purchaseOrderItems"
                  :key="index"
                >
                  <button
                    v-if="item2.item_amt !== item2.item_amt_recieve"
                    type="button"
                    class="btn btn-link btn-color-muted btn-active-color-success p-0"
                    @click="goToEdit(item.id)"
                  >
                    <i class="bi bi-pencil-square"></i>
                  </button>
                </div>
              </td> -->

              <td>
                <a
                  @click="handleApprove(item)"
                  style="color: red; cursor: pointer"
                  >ยกเลิก</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      :loadingUpdate="loadingUpdate"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    />

    <div v-if="isExport">
      <pdfFile :dataExport="dataExport" @generatedFile="generatedFile" />
    </div>

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import purchaseApi from "@/api/purchase/";
import whApi from "@/api/warehouse/";

import DialogConfirmByPass from "./dialogConfirmByPass.vue";
import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import moment from "moment";

import pdfFile from "../../components/files/purchaseFile.vue";

export default {
  components: {
    DialogConfirmByPass,
    Pagination,
    Loader,
    pdfFile,
  },
  setup() {
    document.title = "J&N | รับสินค้า - เข้าคลังแล้ว";
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    showing1: null,

    dialogConfirmByPass: false,
    isExport: false,
    loadingExport: false,
    indexExport: "",

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    allData: [],

    dataExport: {
      id: 1,
      name: 2,
    },
  }),

  created() {
    this.getAll();
  },

  methods: {
    async getAll(method) {
      this.loading = true;
      let responseData = [];
      let companyId = localStorage.getItem("companyId");
      try {
        responseData = await purchaseApi.purchaseOrder.getAllByStatus("3");
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.dataItemsShow = responseData.data;
        this.tableItems = responseData.data;
        this.allData = responseData.data;
        this.loading = false;
        if (method === 1) {
          await this.$router.push({
            query: {
              page: this.$route.query.page,
              get: 1,
            },
          });
        }
      }
    },

    // find product amt !== receive
    receiveItemNotComplete(items) {
      console.log(items);
      items.forEach((element, index) => {
        console.log({ element });
        element.purchaseOrderItems.forEach((element2) => {
          console.log({ element2 });
        });
      });
    },
    // cancelReceipt(id) {
    //   Swal.fire({
    //     icon: "question",
    //     title: "ตกการยกเลิกรับสินค้าเข้าคลังหรือไม่ ?",
    //     showCancelButton: true,
    //   }).then((result) => {
    //     if (result.confirmed) {
    //       this.confirmCancelReceipt(id);
    //     }
    //   });
    // },
    // async confirmCancelReceipt(id) {
    //   let updateResponse = [];

    //   this.form = {
    //     ...this.form,
    //     status: "0",
    //     companyId: parseInt(localStorage.getItem("companyId")),
    //   };
    //   this.loadingUpdate = true;
    //   updateResponse = await purchaseApi.purchaseOrder.update(id, this.form);
    //   if (updateResponse.response_status === "SUCCESS") {
    //     Swal.fire({
    //       icon: "success",
    //       title: `ยกเลิกรับสินค้าสำเร็จ`,
    //       showConfirmButton: false,
    //       timer: 1500,
    //     }).then(() => {
    //       this.$router.push({
    //         path: "/purchase/PU2-3",
    //         query: {},
    //       });
    //     });
    //     this.loadingUpdate = false;
    //   } else {
    //     Swal.fire({
    //       icon: "error",
    //       title: "ยกเลิกรับสินค้าไม่สำเร็จ !",
    //       showConfirmButton: false,
    //       timer: 1500,
    //     });
    //     this.loadingUpdate = false;
    //   }
    // },

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end
    goToNew() {
      this.$router.push("/warehouse/WH4/new");
    },
    goToEdit(id) {
      this.$router.push({
        path: "/purchase/PU2-3/productIntoWarehouse",
        query: {
          id: id,
          method: "edit",
        },
      });
    },
    gotoImportExcel() {
      this.$router.push("/warehouse/WH4/importexcel");
    },
    goToDelete() {},

    async statusApprove(isApprove) {
      let updateResponse = [];
      const id = this.itemApprove.id;

      this.itemApprove = {
        ...this.itemApprove,
        status: isApprove ? 1 : 3,
        receive_date: "",
      };

      updateResponse = await purchaseApi.purchaseOrder.update(
        id,
        this.itemApprove
      );
      if (updateResponse.response_status === "SUCCESS") {
        if (isApprove) {
          Swal.fire({
            icon: "error",
            title: `ยกเลิกรับสินค้าเข้าคลัง !`,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.updateProduct(isApprove);
          });
        }
        // else {
        //   Swal.fire({
        //     icon: "error",
        //     title: `ไม่อนุมัติ`,
        //     showConfirmButton: false,
        //     timer: 1500,
        //   });
        // }
        this.loadingUpdate = false;
        this.getAll(1);
      } else {
        Swal.fire({
          icon: "warning",
          title: "ยกเลิกรับสินค้าไม่สำเร็จ !",
          Text: "เกิดข้อผิดพลาด !",
          showConfirmButton: false,
          timer: 1500,
        });
        this.loadingUpdate = false;
      }
    },

    // update item
    async updateProduct(isApprove) {
      if (isApprove) {
        this.itemApprove = {
          ...this.itemApprove,
          // status: isApprove ? 1 : 2,
        };
        for (const element of this.itemApprove.purchaseOrderItems) {
          console.log({ element });
          await this.getOneProduct(
            element.product.id,
            element.item_amt_recieve
          );
        }
      }
    },

    async getOneProduct(id, inTransit) {
      // console.log({ inTransit });
      let getOneResponse = {};
      try {
        getOneResponse = await whApi.product.getOne(id);
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.updateProductInTransit(inTransit, getOneResponse.data);
      }
    },
    async updateProductInTransit(inTransit, item) {
      let updateResponse = [];

      const body = {
        ...item,
        product_bring_qty:
          parseInt(item.product_bring_qty) - parseInt(inTransit),
        product_in_transit:
          parseInt(item.product_in_transit) + parseInt(inTransit),
      };

      updateResponse = await whApi.product.update(item.id, body);
      if (updateResponse.response_status !== "SUCCESS") {
        Swal.fire({
          icon: "error",
          html: `สินค้ารหัส : ${item.product_code} จำนวน: ${inTransit} เพิ่มเข้า IN TRANSIT ไม่สำเร็จ !`,
          timer: 4000,
        });
      }
    },
    // update item

    handleApprove(item) {
      this.itemApprove = { ...item };
      this.dialogConfirmByPass = true;
    },
    async submitConfirmPass(isApprove) {
      this.statusApprove(isApprove);
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.dialogConfirmByPass = false;
    },
    formatDate(date) {
      if (date) {
        const oldDate = moment(date).format("DD/MM/YYYY");

        return oldDate;
      } else {
        return "-";
      }
    },

    async search() {
      this.loading = true;

      const status = ["3"];

      const responseSearch = await purchaseApi.purchaseOrder.search(
        {
          search: this.searchInput,
        },
        status
      );

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },

    // Export file
    goToExport(item, index) {
      this.dataExport = item;
      this.indexExport = index;
      this.loadingExport = true;
      this.isExport = true;
    },
    generatedFile() {
      this.isExport = false;
    },
    hoverExportBtn() {
      this.loadingExport = false;
      this.isExport = false;
    },

    async search() {
      this.loading = true;

      const status = ["0", "1", "2"];

      const responseSearch = await purchaseApi.purchaseOrder.search(
        {
          search: this.searchInput,
        },
        status
      );

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },
  },
};
</script>
